import React, { useEffect, useState } from "react";
import ContrastCheck from './pages/contrast-check'
import ColorPalette from "./pages/color-palette";
import DesignTokens from "./pages/design-tokens";
import Home from "./pages/home";
import IconsList from "./pages/icons-list";

function App() {
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const hash = window.location.hash;
    if(!!hash) setCurrentPage(hash.replace('#', ''))
  }, [window.location.hash]);

  const pages = [
    {
      name: "Diretrizes da UI",
			url: 'https://fretefy-sa.notion.site/Diretrizes-da-interface-do-usu-rio-c564e999df364b798b73b22af5515d01?pvs=4',
      description: "Regras básicas de design e objetivos para a plataforma Fretefy bem como nossos princípios de desenvolvimento de interfaces",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM205.3 160c-33.8 0-61.3 27.4-61.3 61.3c0 16.2 6.5 31.8 17.9 43.3l82.7 82.7c6.2 6.2 16.4 6.2 22.6 0l82.7-82.7c11.5-11.5 17.9-27.1 17.9-43.3c0-33.8-27.4-61.3-61.3-61.3c-16.2 0-31.8 6.5-43.3 17.9l-7.4 7.4-7.4-7.4c-11.5-11.5-27.1-17.9-43.3-17.9z" fill="#FF0F80"/></svg>'
    },
		{
      name: "Definições de design",
			url: 'https://zeroheight.com/277f70655/p/0067b6-fretefy-design-system',
      description: "Definições dos padrões visuais da interface, como quando utilizar cada variação dos botões ou quando deve utilizar 'switch' ao invés de 'checkbox'.",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M13.4 439l23-78.1c4.2-14.1 11.8-27 22.2-37.4L362.7 19.3c25-25 65.5-25 90.5 0l39.4 39.4c3.1 3.1 5.9 6.5 8.2 10c16.4 24.8 13.7 58.6-8.2 80.5L188.5 453.4c-1.3 1.3-2.6 2.6-4 3.8c-9.6 8.5-21 14.8-33.4 18.4L73 498.6 30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L13.4 439zm62.2-41.2L59.4 452.6l54.8-16.1 23.4-6.9c6.4-1.9 12.3-5.4 17-10.1L383 191 321 129 92.5 357.4c-.6 .6-1.2 1.2-1.7 1.8c-3.9 4.4-6.7 9.6-8.4 15.2l-6.9 23.4zM173.3 27.3l65.2 65.2-33.9 33.9-26.2-26.2-23 23c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l23-23L139.3 61.3c-6.2-6.2-16.4-6.2-22.6 0L61.3 116.7c-6.2 6.2-6.2 16.4 0 22.6l65.2 65.2L92.5 238.5 27.3 173.3c-25-25-25-65.5 0-90.5L82.7 27.3c25-25 65.5-25 90.5 0zM372.7 450.7c6.2 6.2 16.4 6.2 22.6 0l55.4-55.4c6.2-6.2 6.2-16.4 0-22.6l-16.4-16.4-23 23c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l23-23-26.2-26.2 33.9-33.9 65.2 65.2c25 25 25 65.5 0 90.5l-55.4 55.4c-25 25-65.5 25-90.5 0l-65.2-65.2 33.9-33.9 65.2 65.2z" fill="currentColor"/></svg>'
    },
    {
      name: "Firulis",
			url: 'https://design.fretefy.com.br/firulis/',
      description: "Pacote oficial de componentes em angular para desenvolvimento das interfaces.",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M185.7 268.1h76.2l-38.1-91.6-38.1 91.6zM223.8 32L16 106.4l31.8 275.7 176 97.9 176-97.9 31.8-275.7zM354 373.8h-48.6l-26.2-65.4H168.6l-26.2 65.4H93.7L223.8 81.5z" fill="#F73000"/></svg>'
    },
    {
      name: "Paleta de cores",
      slug: "palette",
      description: "Grade com a paleta de cores das interfaces do Fretefy e suas variações.",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M0 400c0 61.9 50.1 112 112 112l336 0c35.3 0 64-28.7 64-64l0-96c0-35.3-28.7-64-64-64l-62.1 0 50.7-50.7c25-25 25-65.5 0-90.5L365.3 75.3c-25-25-65.5-25-90.5 0L224 126.1 224 64c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 400zm448 64l-238.1 0 128-128L448 336c8.8 0 16 7.2 16 16l0 96c0 8.8-7.2 16-16 16zM402.7 203.3L224 382.1l0-188.1 84.7-84.7c6.2-6.2 16.4-6.2 22.6 0l71.4 71.4c6.2 6.2 6.2 16.4 0 22.6zM112 464c-35.3 0-64-28.7-64-64l0-96 128 0 0 96c0 35.3-28.7 64-64 64zM48 256l0-80 128 0 0 80L48 256zm0-128l0-64c0-8.8 7.2-16 16-16l96 0c8.8 0 16 7.2 16 16l0 64L48 128zm64 296a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" fill="#00EDE8"/></svg>'
    },
    {
      name: "Validador de contraste",
      slug: "contrast",
      description: "Ferramenta de verificação de acessibilidade para validar o contraste de cores e tamanho da fonte com base nos padrões WCAG 2.0 e WCAG 2.1.",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M464 256c0-114.9-93.1-208-208-208l0 416c114.9 0 208-93.1 208-208zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" fill="#002A68"/></svg>'
    }, 
    {
      name: "Design Tokens",
      slug: "tokens",
      description: "Os tokens de design são as definições de design, padronizadas em variáveis para reutilização, mantendo a consistência visual das interfaces.",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 512 512"><path class="fa-primary" d="M304 96a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-19.6 91.7c-9 2.8-18.5 4.3-28.4 4.3s-19.4-1.5-28.4-4.3L158.1 306.4C187.9 320.3 221 328 256 328c85.8 0 160.8-46.6 201-116c6.6-11.5 21.3-15.4 32.8-8.8s15.4 21.3 8.8 32.8C450.2 319.6 359.7 376 256 376c-43.8 0-85.3-10.1-122.2-28L44.7 500.1c-6.7 11.4-21.4 15.3-32.8 8.6S-3.4 487.3 3.3 475.9L92.6 323.4C60.4 300.2 33.4 270.4 13.5 236c-6.6-11.5-2.7-26.2 8.8-32.8s26.2-2.7 32.8 8.8c15.8 27.2 36.9 51 61.9 69.7l69.8-119.2C170.2 145.2 160 121.8 160 96c0-53 43-96 96-96s96 43 96 96c0 25.8-10.2 49.2-26.7 66.5l53.5 91.4c-12.6 9.8-26.4 18.1-41.1 24.7l-53.2-90.9zM508.7 475.9c6.7 11.4 2.9 26.1-8.6 32.8s-26.1 2.9-32.8-8.6L394.4 375.7c14.4-7.1 28.2-15.4 41.2-24.6l73 124.8z" fill="#6C6E71"/></svg>'
    },
    {
      name: "Icones",
      slug: "icons",
      description: "Lista dos ícones utilizados no sistema",
      icon: '<svg xmlns="http://www.w3.org/2000/svg"  height="48" width="48" viewBox="0 0 512 512"><path d="M59.6 54.4c11.1-9.4 30.8-8.7 43.5 3.7l8 8.1 17.5 17.8 17.1-18.3L153 58c12.5-12 31.7-13.1 43.8-3.4c14.2 12.1 14.8 33.3 2 46.2c0 0 0 0 0 0l-70.7 70.8-71-70.8s0 0 0 0C44.3 87.9 44.9 66.6 59.5 54.5c0 0 0 0 0 0l.1-.1zM28.8 17.6C-7.7 47.9-9.4 102 23.1 134.6c0 0 0 0 0 0L101.7 213c14.7 15.1 38.3 14.1 52.5 .4c0 0 0 0 0 0l.3-.3 78.3-78.5s0 0 0 0C265.4 102 263.6 48 227.6 17.7c0 0 0 0 0 0l-.3-.3c-29.9-24.3-71-21.9-99.3-1C99.7-4.4 58.2-7 28.8 17.6zM512 24c0-7.2-3.2-14-8.8-18.6s-12.9-6.4-19.9-5l-160 32C312.1 34.7 304 44.6 304 56l0 105.5c-5.1-1-10.5-1.5-16-1.5c-35.3 0-64 21.5-64 48s28.7 48 64 48s64-21.5 64-48l0-132.3L464 53.3l0 76.2c-5.1-1-10.5-1.5-16-1.5c-35.3 0-64 21.5-64 48s28.7 48 64 48s64-21.5 64-48l0-152zM106.9 309.5l2.7-5.5 68.7 0 2.7 5.5c8.1 16.3 24.8 26.5 42.9 26.5c8.8 0 16 7.2 16 16l0 96c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-96c0-8.8 7.2-16 16-16c18.2 0 34.8-10.3 42.9-26.5zM224 288l-7.2-14.3c-5.4-10.8-16.5-17.7-28.6-17.7l-88.4 0c-12.1 0-23.2 6.8-28.6 17.7L64 288c-35.3 0-64 28.7-64 64l0 96c0 35.3 28.7 64 64 64l160 0c35.3 0 64-28.7 64-64l0-96c0-35.3-28.7-64-64-64zM192 392a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM473.4 259.1c-6-4.4-14.3-4-19.9 .9l-128 112c-5 4.4-6.8 11.4-4.4 17.7s8.3 10.4 15 10.4l55.7 0-38.4 89.7c-2.9 6.9-.7 14.9 5.3 19.2s14.3 4 19.9-.9l128-112c5-4.4 6.8-11.4 4.4-17.7s-8.3-10.4-15-10.4l-55.7 0 38.4-89.7c2.9-6.9 .7-14.9-5.3-19.2z" fill="#00C703"/></svg>'
    },
  
  ];

  if(currentPage == 'home'){
    return <Home pages={pages} setCurrentPage={setCurrentPage} />

  }

  return (
    <div className="d-flex flex-grow-1 flex-column">
      <header className="top-bar">
      <h1 className="title spacing__sm--right">Product Design System</h1>

        <menu className="nav-tabs">
          <ul className="nav-tabs__list">
            {pages.map(page => (
              <li>
                <a href={!!page.slug ? `#${page.slug}` : page.url} className={`nav-tabs__item  ${currentPage === page.slug && 'nav-tabs__item--active'}`} onClick={() => !!page.slug && setCurrentPage(page.slug)} target={!!page.url ? `_blank` : '_self'}>
                  <span className="nav-tabs__text">{page.name}</span>
                </a>
              </li>

            ))}
          </ul>
        </menu>
      </header>
      <main className="flex-grow-1 overflow-auto"> 
        {currentPage === 'contrast' && <ContrastCheck />}
        {currentPage === 'palette' && <ColorPalette />}
        {currentPage === 'tokens' && <DesignTokens />}
        {currentPage === 'icons' && <IconsList />}
      </main>
    </div>
  )
}

export default App;
